<template>
  <v-app-bar app color="background" elevation="0">
    <v-app-bar-nav-icon color="primary" @click.stop="updateDrawer"
      ><v-icon small>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title>{{ $t("app.title") }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon> mdi-bell-outline </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.notifications") }}</span>
      </v-tooltip>
      <v-menu :close-on-content-click="closeOnClick" offset-y max-width="300px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text :ripple="false" active-class="">
            <v-avatar class="mr-2">
              <v-icon> mdi-account-circle</v-icon>
            </v-avatar>
            <span class="d-none d-sm-flex pr-1 text-body-2 text-capitalize">
              {{ $t("app.hello") }} {{ user.firstName }}
            </span>
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-inline-flex"
                  ><span class="d-flex align-center"
                    >{{ $t("app.hello") }} {{ user.firstName }} {{ user.lastName }}</span
                  >
                  <v-spacer></v-spacer
                  ><span class="d-flex"
                    ><v-btn icon :to="{ name: 'edit-profile' }" text
                      ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
                    ></span
                  ></v-list-item-title
                >
                <v-divider class="my-4"></v-divider>
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="yellow darken-3" left>mdi-star</v-icon>
                  {{ $t("navigation.availablePoints") }} :
                  <span class="font-weight-bold">{{
                    formatNumber(user.availablePoints) || "/"
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="success" left>mdi-star</v-icon>
                  {{ $t("navigation.earnedPoints") }} :
                  <span class="font-weight-bold">{{ formatNumber(user.earnedPoints) || "/" }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="info" left>mdi-star</v-icon>
                  {{ $t("navigation.reservedPoints") }} :
                  <span class="font-weight-bold">{{
                    formatNumber(user.reservedPoints) || "/"
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="my-1"
                  ><v-icon color="error" left>mdi-star</v-icon> {{ $t("navigation.spentPoints") }} :
                  <span class="font-weight-bold">{{ formatNumber(user.spentPoints) || "/" }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.city" class="my-1"
                  ><v-icon left>mdi-city</v-icon>
                  {{ user?.city?.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.shop" class="my-1"
                  ><v-icon color="teal" left>mdi-briefcase-variant</v-icon>
                  {{ user?.shop?.address }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item link :to="{ name: 'logout' }">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              {{ $t("app.logout") }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      search: "",
      closeOnClick: false
    };
  },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    }
  },
  methods: {
    updateDrawer() {
      Event.$emit("admin-drawer-update", !this.drawer);
    }
  }
};
</script>
